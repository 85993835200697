import Vue from "vue";
import VueRouter from "vue-router";
import authNavigationGuard from "../../middlewares/client/authNavigationGuard";
import {
  allowAdmin,
  allowUser,
} from "../../middlewares/client/roleNavigationGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../../views/Login"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../../views/Dashboard"),
    beforeEnter: allowUser,
  },
  {
    path: "/members",
    name: "members",
    component: () => import("../../views/Members"),
    beforeEnter: allowUser,
  },
  {
    path: "/members/new",
    name: "newMember",
    component: () => import("../../views/NewMember"),
    beforeEnter: allowUser,
  },
  {
    path: "/member/:id",
    name: "member",
    component: () => import("../../views/Member"),
    beforeEnter: allowUser,
  },
  {
    path: "/field-activities",
    name: "fieldActivities",
    component: () => import("../../views/FieldActivities"),
    beforeEnter: allowUser,
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../../views/Users"),
    beforeEnter: allowAdmin,
  },
  {
    path: "/logs",
    name: "logs",
    component: () => import("../../views/Logs"),
    beforeEnter: allowAdmin,
  },
  {
    path: "/404",
    alias: "*",
    name: "notFound",
    component: () => import("../../views/Error404"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) =>
  authNavigationGuard(to, from, next)
);

export default router;
