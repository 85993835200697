import store from "../../store";

// Kontroliramo koji tip korisnika (Admin ili User) ima pristup kojim stranicama

const allowAdmin = async (to, from, next) => {
  if (!store.state.account.user.is_admin) {
    next({
      name: "dashboard",
    });
  }
  next();
};

const allowUser = async (to, from, next) => {
  if (store.state.account.user.is_admin) {
    next({
      name: "users",
    });
  }
  next();
};

export { allowAdmin, allowUser };
