import apiRequest from "../helpers/apiRequest";

const account = {
  namespaced: true,

  state: () => ({
    user: {},
  }),

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },

  getters: {
    isAuthenticated: (state) => {
      if (Object.keys(state.user).length === 0) {
        return false;
      }

      return true;
    },

    dphName: (state) => {
      if (state.user.is_admin) {
        return "Administrator";
      }

      if (state.user.sf_data) {
        return state.user.sf_data.Name;
      }
    },
  },

  actions: {
    async fetchUser(context) {
      context.commit("setUser", {});

      try {
        const user = await apiRequest("get", "dphs/me");
        context.commit("setUser", user);
      } catch (error) {
        console.error(error);
      }
    },
  },
};

export default account;
