<template>
  <v-navigation-drawer v-model="$store.state.sideMenuShown" app>
    <img src="../assets/dph-app-logo.png" id="sidebar-logo" alt="DPH app" />

    <v-card flat>
      <v-list flat v-show="!isAdmin">
        <v-list-item-group v-model="selectedItem" color="#1358a3">
          <v-list-item v-for="(item, i) in userItems" :key="i" :to="item.link">
            <v-list-item-icon>
              <v-icon large v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-list flat v-show="isAdmin">
        <v-list-item-group v-model="selectedItem" color="#1358a3">
          <v-list-item v-for="(item, i) in adminItems" :key="i" :to="item.link">
            <v-list-item-icon>
              <v-icon large v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Sidebar",

  data: () => ({
    selectedItem: 1,
    userItems: [
      {
        text: "Moj DPH",
        icon: "mdi-home-variant",
        link: "/dashboard",
      },
      {
        text: "Članstvo",
        icon: "mdi-account-multiple",
        link: "/members",
      },
      {
        text: "Terenske akcije",
        icon: "mdi-flag-checkered",
        link: "/field-activities",
      },
    ],
    adminItems: [
      {
        text: "Korisnici",
        icon: "mdi-account",
        link: "/users",
      },
      {
        text: "Logovi",
        icon: "mdi-clipboard-text-outline",
        link: "/logs",
      },
    ],
  }),

  computed: {
    isAdmin() {
      return this.$store.state.account.user.is_admin;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  margin-top: 80px;
  padding-left: 20px;
}
#sidebar-logo {
  width: 50%;
  margin-left: 40px;
  margin-top: 18px;
}
</style>
