import store from "../../store";

const authNavigationGuard = async (to, from, next) => {
  if (!localStorage.getItem("token")) {
    if (to.name === "login") {
      next();
    } else {
      // Redirect na login stranicu ako nema tokena
      next({ name: "login" });
    }
  } else {
    try {
      await store.dispatch("account/fetchUser");
    } catch (error) {
      next(new Error("Greška kod dohvata podataka o korisniku."));
    }

    const isAuthenticated = store.getters["account/isAuthenticated"];

    // Ako korisnik nije logiran, redirect na login stranicu
    if (to.name !== "login" && !isAuthenticated) {
      next({
        name: "login",
      });
    }

    // Ako je korisnik logiran a pokušava pristupiti login stranici, redirect na dashboard
    else if (to.name === "login" && isAuthenticated) {
      next({
        name: "dashboard",
      });
    } else next();
  }
};

export default authNavigationGuard;
