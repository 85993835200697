import Vue from "vue";
import Vuex from "vuex";
import apiRequest from "../helpers/apiRequest";

import account from "./account";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
  },

  state: {
    sideMenuShown: true,
    allDphs: [],
    membershipRenewal: {
      isDialogVisible: false,
      members: [],
      renewalStatuses: [],
      type: null,
      completed: false,
    },
  },

  getters: {
    mainMember(state) {
      if (state.membershipRenewal.type == "single") {
        return state.membershipRenewal.members[0];
      }
      return state.membershipRenewal.members.find((user) => {
        return user.Household_Role__c == "Main";
      });
    },

    // Vraća niz nektivnih članova
    inactiveMembers(state) {
      return state.membershipRenewal.members.filter((member) => {
        return member.Active__c == false;
      });
    },
  },

  mutations: {
    toggleSideMenu(state) {
      state.sideMenuShown = !state.sideMenuShown;
    },

    setDphs(state, dphs) {
      state.allDphs = dphs;
    },

    addUsersForMembershipRenewal(state, users) {
      users.forEach((user) => {
        state.membershipRenewal.members.push(user);
        state.membershipRenewal.renewalStatuses.push({
          memberId: user.Id,
          status: null,
        });
      });
    },

    clearMembershipRenewalList(state) {
      state.membershipRenewal.members = [];
      state.membershipRenewal.renewalStatuses = [];
    },

    setUserMembershipRenewalStatus(state, { memberId, status }) {
      state.membershipRenewal.renewalStatuses.find((m) => {
        return m.memberId == memberId;
      }).status = status;
    },

    setTypeOfMembershipRenewal(state, type) {
      state.membershipRenewal.type = type;
    },

    showRenewMembershipDialog(state, isDialogVisible) {
      state.membershipRenewal.isDialogVisible = isDialogVisible;
    },

    setMembershipRenewalCompletionStatus(state, value) {
      state.membershipRenewal.completed = value;
    },
  },

  actions: {
    async fetchDphs(context) {
      context.commit("setDphs", []);

      try {
        const dphs = await apiRequest("get", "dphs");
        context.commit("setDphs", dphs);
      } catch (error) {
        console.error(error);
      }
    },

    openRenewMembershipDialog(context, { users, type }) {
      context.commit("addUsersForMembershipRenewal", users);
      context.commit("setTypeOfMembershipRenewal", type);
      context.commit("setMembershipRenewalCompletionStatus", false);
      context.commit("showRenewMembershipDialog", true);
    },

    closeRenewMembershipDialog(context) {
      context.commit("clearMembershipRenewalList");
      context.commit("setTypeOfMembershipRenewal", null);
      context.commit("showRenewMembershipDialog", false);
    },

    completeMembershipRenewal(context) {
      context.commit("setMembershipRenewalCompletionStatus", true);
    },
  },
});
