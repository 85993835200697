<template>
  <v-row justify="center">
    <v-dialog v-model="dialogShown" v-if="user" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            ><b
              >Obnova članstva
              <span v-if="type == 'family'">(obitelj)</span>:</b
            >
            {{ user.FirstName }} {{ user.LastName }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <h3>Godina</h3>
                <v-divider></v-divider>
                <v-radio-group v-model="year" mandatory>
                  <v-radio :label="currentYear" :value="currentYear"></v-radio>
                  <!-- <v-radio
                    :label="previousYear"
                    :value="previousYear"
                  ></v-radio> -->
                </v-radio-group>
              </v-col>

              <v-col v-if="type != 'family'" cols="12" sm="6">
                <h3>Baza članstva</h3>
                <v-divider></v-divider>
                <v-select
                  required
                  :items="dphs"
                  item-text="Name"
                  item-value="Id"
                  :rules="validationRules.dph"
                  v-model="user.Primary_Affiliate__c"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" v-show="selectableFieldActivities.length">
                <h3>Terenska akcija</h3>
                <v-divider></v-divider>

                <v-radio-group
                  v-model="fieldActivity"
                  v-if="
                    !!activeFieldActivities.length && !!permanentFieldActivity
                  "
                  mandatory
                >
                  <v-radio
                    v-for="activity in selectableFieldActivities"
                    :key="activity.Id"
                    :label="activity.Name"
                    :value="activity.Id"
                  ></v-radio>

                  <v-radio
                    :value="permanentFieldActivity.Id"
                    label="Ne"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                v-if="!permanentFieldActivity && !loading"
              >
                <p class="text-red">
                  Greška! Ne postoji trajna godišnja aktivnost.
                </p>
              </v-col>
            </v-row>

            <v-row v-if="type == 'family'">
              <v-col cols="12" sm="12">
                <h3>Članovi obitelji</h3>
                <v-divider></v-divider>
                <RenewMembershipHouseholdMembers
                  :householdId="user.npsp__HHId__c"
                />
              </v-col>
            </v-row>

            <p class="membership-info-text">
              Ukupni iznos članarine (s popustom):
              <span class="amount">{{ membershipAmountFormatted }} €</span>
            </p>
            <v-divider></v-divider>
          </v-container>
        </v-card-text>

        <v-alert outlined text type="error" v-show="error">
          {{ error }}
        </v-alert>

        <v-alert outlined text type="warning" v-show="warning">
          {{ warning }}
        </v-alert>

        <v-alert outlined text type="success" v-show="success">
          {{ success }}
        </v-alert>

        <v-card-actions v-if="!loading">
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDialog"> Zatvori </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="renewMembership"
            v-show="!success && !!this.permanentFieldActivity"
          >
            Obnovi članstvo
          </v-btn>
        </v-card-actions>

        <div v-else class="clearfix">
          <div class="loading-progress">
            <v-progress-circular
              indeterminate
              color="#1358a3"
            ></v-progress-circular>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apiRequest from "../../helpers/apiRequest";
import RenewMembershipHouseholdMembers from "./RenewMembershipHouseholdMembers.vue";
import mixin from "../../mixins/mixin";

export default {
  name: "RenewMembershipDialog",

  components: { RenewMembershipHouseholdMembers },

  mixins: [mixin],

  data: () => ({
    validationRules: {
      dph: [(v) => !!v || "DPH nije odabran"],
    },
    year: null,
    fieldActivity: null,
    activeFieldActivities: [],
    loading: false,
    error: false,
    success: false,
    warning: false,
  }),

  watch: {
    async dialogShown(value) {
      if (value) {
        this.fetchFieldActivities();
      } else {
        this.year = null;
        this.fieldActivity = null;
      }
    },
  },

  computed: {
    dialogShown() {
      return this.$store.state.membershipRenewal.isDialogVisible;
    },

    user() {
      return this.$store.getters.mainMember;
    },

    type() {
      return this.$store.state.membershipRenewal.type;
    },

    dphs() {
      return this.$store.state.allDphs;
    },

    currentYear() {
      const currentYear = new Date().getFullYear();
      return currentYear.toString();
    },

    previousYear() {
      const previousYear = new Date().getFullYear() - 1;
      return previousYear.toString();
    },

    // Vraća trajnu godišnju aktivnost
    permanentFieldActivity() {
      return this.activeFieldActivities.find(
        (item) => item.Field_activity_type__c == "Trajna godišnja aktivnost"
      );
    },

    // Vraća sve terenske akcije osim trajne godišnje aktivnosti
    selectableFieldActivities() {
      return this.activeFieldActivities.filter(
        (item) => item.Field_activity_type__c != "Trajna godišnja aktivnost"
      );
    },

    membershipAmountFormatted() {
      const amount =
        this.type == "family"
          ? this.user.Account.Proposed_Membership_Amount_Total__c
          : this.user.Proposed_Membership_Amount_Formula__c;

      return this.formatCurrency(amount);
    },

    // Vraća podatke o članovima u formatu za obnovu obiteljskog članstva
    membersRenewalData() {
      return this.$store.getters.inactiveMembers.map((member) => {
        return {
          ContactId: member.Id,
          PrimaryAffiliateID: member.Primary_Affiliate__c,
        };
      });
    },
  },

  methods: {
    async fetchFieldActivities() {
      this.activeFieldActivities = [];
      this.loading = true;

      try {
        this.activeFieldActivities = await apiRequest(
          "get",
          "field-activities/my/active"
        );
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    async renewMembership() {
      this.resetFields();
      this.loading = true;

      try {
        if (this.type == "family") {
          await this.renewHouseholdMembership();
        } else {
          await this.renewSingleMembership();
        }
      } catch (error) {
        this.error = error.response.data.message;
      } finally {
        this.loading = false;
      }
    },

    async renewSingleMembership() {
      await apiRequest("post", `members/${this.user.Id}/new-membership`, {
        year: this.year,
        dphId: this.user.Primary_Affiliate__c,
        membershipAmount: this.user.Proposed_Membership_Amount_Formula__c,
        fieldActivity: this.fieldActivity,
      });

      this.success = "Članstvo uspješno obnovljeno!";
    },

    async renewHouseholdMembership() {
      let response = await apiRequest(
        "post",
        `households/${this.user.npsp__HHId__c}/new-household-membership`,
        {
          fieldActivity: this.fieldActivity,
          membersData: this.membersRenewalData,
        }
      );

      response.results.forEach((contact) => {
        this.$store.commit("setUserMembershipRenewalStatus", {
          memberId: contact.ContactId,
          status: contact.Status == "NOK" ? "error" : "success",
        });
      });

      if (response.results.find((contact) => contact.Status == "NOK")) {
        this.warning = `Došlo je do problema kod obnove članarine za neke od članova obitelji. 
          Ukupno naplaćeni iznos: ${response.total_price} €`;
      } else {
        this.success = `Članstvo uspješno obnovljeno!  
          Ukupno naplaćeni iznos: ${response.total_price} €`;
      }
    },

    resetFields() {
      this.loading = false;
      this.error = false;
      this.success = false;
      this.warning = false;
    },

    closeDialog() {
      this.$store.dispatch("closeRenewMembershipDialog");

      if (this.success) {
        this.$store.dispatch("completeMembershipRenewal");
      }

      this.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.error-text {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.membership-info-text {
  font-weight: 600;
  text-align: right;

  .amount {
    font-weight: bold;
    font-size: 18px;
  }
}

.loading-progress {
  float: right;
  margin-right: 20%;
  min-height: 60px !important;
}

.v-alert {
  margin: 0 10px;
}
</style>
