<template>
  <tr>
    <td width="30%">
      <p>{{ member.FirstName }} {{ member.LastName }}</p>
    </td>
    <td width="50%">
      <v-select
        class="dph-select"
        v-if="!member.Active__c"
        required
        :items="dphs"
        item-text="Name"
        item-value="Id"
        :rules="validationRules.dph"
        v-model="member.Primary_Affiliate__c"
        dense
      ></v-select>

      <p v-else>{{ member.Primary_Affiliate__r.Name }}</p>
    </td>
    <td width="20%">
      <v-icon
        v-show="renewalStatus == 'success'"
        class="member-icon"
        color="#27ae60"
      >
        mdi-check-bold
      </v-icon>

      <v-icon
        v-show="renewalStatus == 'error'"
        class="member-icon"
        color="#ce3137"
      >
        mdi-alert-circle
      </v-icon>
    </td>
  </tr>
</template>

<script>
export default {
  name: "RenewMembershipHouseholdMember",

  props: ["member"],

  data: () => ({
    validationRules: {
      dph: [(v) => !!v || "DPH nije odabran"],
    },
  }),

  computed: {
    dphs() {
      return this.$store.state.allDphs;
    },

    renewalStatus() {
      const renewalData =
        this.$store.state.membershipRenewal.renewalStatuses.find((m) => {
          return m.memberId == this.member.Id;
        });

      return renewalData ? renewalData.status : null;
    },
  },
};
</script>

<style scoped>
.member-loading,
.member-icon {
  height: 1rem !important;
  margin-bottom: 10px;
}

tr td {
  padding-right: 2rem;
  line-height: initial;
}

.dph-select {
  font-size: 0.85rem;
}
</style>
