<template>
  <v-app-bar flat hide-on-scroll app>
    <v-app-bar-nav-icon
      @click="$store.commit('toggleSideMenu')"
    ></v-app-bar-nav-icon>

    <div id="navbar-menu">
      <h3>
        {{ $store.getters["account/dphName"] }}
      </h3>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"> mdi-chevron-down </v-icon>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title @click="logOut"> Odjavi se </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "Navbar",

  methods: {
    logOut() {
      localStorage.removeItem("token");
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
#navbar-menu {
  position: absolute;
  right: 1rem;

  h3 {
    display: inline-block;
    color: #1358a3;
  }
}

.v-list-item:hover {
  cursor: pointer;
}
</style>
