/**
 * Contains shared methods that could be used in various views.
 */

export default {
  methods: {
    /**
     * Returns the date in format: DD.MM.YYYY
     */
    formatDate(dateString) {
      if (!dateString) {
        return "-";
      }

      const date = new Date(dateString);
      return date.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },

    /**
     * Returns the number in format: 1.234,56
     */
    formatCurrency(number) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(number);
    },
  },
};
