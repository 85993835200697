<template>
  <div>
    <v-app>
      <div v-if="!isLoginPage">
        <Navbar />

        <Sidebar />
      </div>

      <v-main>
        <v-container fluid>
          <v-row no-gutters>
            <router-view></router-view>
          </v-row>
        </v-container>
      </v-main>

      <RenewMembershipDialog />

      <Footer />
    </v-app>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Sidebar from "./components/Sidebar.vue";
import Footer from "./components/Footer.vue";
import RenewMembershipDialog from "./components/membership/RenewMembershipDialog.vue";

export default {
  name: "Home",

  components: {
    Navbar,
    Sidebar,
    Footer,
    RenewMembershipDialog,
  },

  data() {
    return {
      isLoginPage: true,
    };
  },

  beforeUpdate() {
    if (this.$route.name != "login") {
      this.isLoginPage = false;
    }
  },

  created() {
    document.title = "DPH app";
  },
};
</script>
