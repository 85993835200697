<template>
  <div>
    <table class="household-members" v-if="!loading && members.length">
      <thead>
        <tr>
          <th class="text-left">Ime i prezime</th>
          <th class="text-left">Baza članstva</th>
        </tr>
      </thead>
      <tbody>
        <RenewMembershipHouseholdMember
          v-for="member in members"
          :key="member.Id"
          :member="member"
        />
      </tbody>
    </table>

    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <div v-if="loading">
            <v-progress-circular
              indeterminate
              color="#1358a3"
            ></v-progress-circular>
          </div>

          <p v-show="error" class="error-text">{{ error }}</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import apiRequest from "../../helpers/apiRequest";
import RenewMembershipHouseholdMember from "./RenewMembershipHouseholdMember.vue";

export default {
  name: "RenewMembershipHouseholdMembers",

  components: { RenewMembershipHouseholdMember },

  props: ["householdId"],

  data() {
    return {
      loading: false,
      error: false,
    };
  },

  mounted() {
    this.fetchHouseholdMembers();
  },

  computed: {
    members() {
      return this.$store.state.membershipRenewal.members;
    },
  },

  methods: {
    async fetchHouseholdMembers() {
      this.error = false;
      this.loading = true;

      try {
        const householdMembers = await apiRequest(
          "get",
          `households/${this.householdId}/members`
        );

        // U listu za obnovu članarine dodajemo sve članove obitelji osim nositelja (jer je već dodan)
        this.$store.commit(
          "addUsersForMembershipRenewal",
          householdMembers.filter((member) => {
            return member.Id != this.$store.getters.mainMember.Id;
          })
        );
      } catch (error) {
        this.error = error.response.data.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.household-members {
  margin-top: 10px;
}
</style>
