<template>
  <v-card height="400px" id="main-footer">
    <v-footer>
      <v-card flat tile width="100%" class="text-center">
        <v-card-text>
          <p>
            <strong>DPHapp</strong> by
            <a href="https://nashajduk.hr/">Naš Hajduk</a>.
          </p>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
#main-footer {
  margin-top: 100px;

  .v-card__text {
    padding-top: 30px;
    background-color: #f5f5f5;

    a {
      color: #1358a3;
      text-decoration: none;
    }
  }
}
</style>
