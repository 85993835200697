import axios from "axios";

/**
 * Wrapper metoda za axios request.
 * @param {String} method
 * @param {String} path
 * @param {Object} payload
 * @returns Promise
 */
const apiRequest = async (method, path, payload = {}) => {
  const token = localStorage.getItem("token");

  const axiosHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  let response = null;

  if (method == "get") {
    if (Object.keys(payload).length > 0) {
      const params = new URLSearchParams(payload);
      path += `?${params}`;
    }
    response = await axios.get(`/api/${path}`, {
      headers: axiosHeaders,
    });
  } else if (method == "post") {
    response = await axios.post(`/api/${path}`, payload, {
      headers: axiosHeaders,
    });
  } else if (method == "patch") {
    response = await axios.patch(`/api/${path}`, payload, {
      headers: axiosHeaders,
    });
  } else if (method == "delete") {
    response = await axios.delete(`/api/${path}`, {
      headers: axiosHeaders,
    });
  }

  if (!response) {
    throw new Error("Greška prilikom izvođenja api requesta.");
  }

  return response.data;
};

export default apiRequest;
